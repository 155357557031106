*,
*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

body,
html,
#root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;

  line-height: 1.5;
  margin: 0;
  padding: 0;

  /* mobile viewport bug fix */
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  body,
  html,
  #root {
    font-size: 12px;
  }
}

#root {
  display: flex;
  flex-direction: column;

  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;

  /* mobile viewport bug fix */
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;

  overflow: hidden;
}
