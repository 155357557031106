/* ------------------------------------------------------------------------------------------------------------ */

.App {
  display: flex;
  flex-direction: row;
  flex: 1;

  /*padding: 2rem;*/
  overflow: hidden;
}

:root {
  --row-height: 4rem;
  --row-header-width: 6rem;
  --column-width: 16rem; /*todo - this needs to match in the code?*/
  --column-header-height: 4rem;
  --odd-stripe-color: rgba(0, 0, 0, 0.1);
  --even-stripe-color: rgba(0, 0, 0, 0.08);
  --background: #001f3f;
}

.epg {
  display: flex;
  flex-direction: row;
  flex: 1;

  background: hsl(210, 100%, 12%);

  overflow: hidden;
}

.corner {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--row-header-width);
  height: var(--column-header-height);
  background: hsl(210, 100%, 12%);
  z-index: 3;
}

.scrollable {
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow: scroll;
}

.outer-pane {
  width: calc(24 * var(--column-width) + var(--row-header-width));
}

.header-pane {
  display: flex;
  flex-direction: row;
  flex: 1;

  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
}

.body-pane {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.content {
  height: 100%;
  width: 100%;

  background: #fff;
}

.column-headers {
  display: flex;
  flex-direction: row;
  flex: 1 1;

  background: var(--background);

  padding-left: var(--row-header-width);

  position: sticky;
  top: 0;

  width: 100%;

  line-height: var(--column-header-height);
  height: var(--column-header-height);
  min-height: var(--column-header-height);
  z-index: 2;

  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
}

.column-header {
  padding-left: 1rem;
  border-right: 1px solid rgba(222, 222, 222, 0.5);

  color: #fff;
  background: linear-gradient(
    90deg,
    hsl(210, 100%, 12%) 0%,
    hsl(210, 100%, 18%) 100%
  );
}

.row-headers {
  position: sticky;
  left: 0;
  width: var(--row-header-width);
  min-width: var(--row-header-width);

  height: 100%;
  z-index: 1;

  box-shadow: 2px 0 10px -2px rgba(0, 0, 0, 0.5);
}

.row-header {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  align-items: center;

  text-align: center;

  height: var(--row-height);
  line-height: var(--row-height);
  min-height: var(--row-height);

  border-bottom: 1px solid rgba(222, 222, 222, 0.5);

  color: #fff;
  background: linear-gradient(
    180deg,
    hsl(210, 100%, 12%) 0%,
    hsl(210, 100%, 18%) 100%
  );
}

.row {
  height: var(--row-height);
  line-height: var(--row-height);
  min-height: var(--row-height);

  border-bottom: 1px solid #aaa;

  display: flex;
  flex: 1;
  background-color: var(--odd-stripe-color);
  background-image: linear-gradient(
    to left top,
    transparent 0%,
    transparent 25%,
    hsla(240, 9%, 60%, 0.1) 25%,
    hsla(240, 9%, 60%, 0.1) 50%,
    transparent 50%,
    transparent 75%,
    hsla(240, 9%, 60%, 0.1) 75%
  );
  background-size: 1rem 1rem;
  align-items: center;
  justify-content: center;
  cursor: move;
}

.row:nth-child(even) {
  background-color: var(--even-stripe-color);
}

.row .Cell {
  background: #f1f1f1;
}
.row:nth-child(even) .Cell {
  background: #fff;
}

.row:last-child {
  border-bottom: none;
}

/* ------------------------------------------------------------------------------------------------------------ */

.Cell {
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  right: 0;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.Cell.RunsPastMidnight {
  mask-image: linear-gradient(
      to top right,
      white,
      white 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(to left, transparent 0.5rem, black 0.5rem, white);
  mask-size: 0.5rem 0.5rem, 100% 100%;
  mask-repeat: repeat-y;
  mask-position: top right;
}

.Cell img {
  margin-left: 0.25rem;
}
.Cell .a2x3 {
  width: 2rem;
  height: 3rem;
}
.Cell .a4x3 {
  width: 4rem;
  height: 3rem;
}

.Cell .Details {
  display: flex;
  flex-direction: column;
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding: 0 0.25rem;
  line-height: 1.5rem;
}

.Cell .Name {
  font-size: 1rem;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Cell .SubName {
  font-size: 0.75rem;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CellDisplay {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.NetworkHeader {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.NetworkHeader img {
  width: 3rem;
}

.NetworkHeader .NetworkName {
  line-height: 3rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: #fff;

  padding: 0 0.5rem;
  width: var(--row-header-width);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NetworkHeader .NetworkNumber {
  line-height: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: #fff;
}
